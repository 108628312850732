import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import menu from "../images/menu.svg"
import close from "../images/close.svg"
import wlogo from "../images/flogo.png"
import fins from "../images/fins.svg"
import ftw from "../images/ftw.svg"
import flin from "../images/flin.svg"
import fyt from "../images/fyt.svg"
import ffb from "../images/ffb.svg"
import Img from "gatsby-image"
import { Navbar, Nav, Row } from "react-bootstrap"
import "../dist/css/style.css"
const mainNavs = [
  {
    to: "/how-to-join",
    text: "HOW TO JOIN",
  },
  {
    to: "/life-as-a-fellow",
    text: "LIFE AS A FELLOW",
  },
  {
    to: "/life-as-alumni",
    text: "LIFE AS ALUMNI",
  },
]
const subNavs = [
  {
    to: "/what-we-do",
    text: "WHAT WE DO",
  },
  {
    to: "/our-impact",
    text: "OUR IMPACT",
  },
  {
    to: "/innovation",
    text: "INNOVATION",
  },
  {
    to: "/support-us",
    text: "SUPPORT US",
  },
]
const Header = propTypes => {
  const [isSticky, setSticky] = useState(false)
  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 43) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  // useEffect(() => {
  //   return () => {
  //     setSticky(false)
  //   }
  // }, [propTypes.pageName])

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 219) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      site {
        siteMetadata {
          instagram
          facebook
          linkedin
          youtube
          twitter
        }
      }
    }
  `)
  // console.log(propTypes)

  const curNav = mainNavs.map(nav => {
    if (propTypes.pageName.toUpperCase() === nav.text) {
      // console.log("-----------------------" + propTypes.siteTitle)
      return (
        <Link className="nav-link active" key={nav.text} to={nav.to}>
          {nav.text}
        </Link>
      )
    } else {
      return (
        <Link className="nav-link" key={nav.text} to={nav.to}>
          {nav.text}
        </Link>
      )
    }
  })

  const curNav2 = subNavs.map(nav => {
    if (propTypes.pageName.toUpperCase() === nav.text) {
      return (
        <Link to={nav.to} key={nav.text} className="subNav-link active">
          <li className="top-nav-item">{nav.text}</li>
        </Link>
      )
    } else {
      return (
        <Link to={nav.to} key={nav.text} className="subNav-link">
          <li className="top-nav-item">{nav.text}</li>
        </Link>
      )
    }
  })

  return (
    <header className={`w-100 ${isSticky ? " sticky" : ""}`}>
      <div className="top-bar d-none d-lg-block container-fluid text-right">
        <ul className="top-nav">
          {/* <Link to="/what-we-do">
            <li className="top-nav-item">WHAT WE DO</li>
          </Link>
          <Link to="/our-impact">
            <li className="top-nav-item">OUR IMPACT</li>
          </Link>
          <Link to="/innovation">
            <li className="top-nav-item">INNOVATION</li>
          </Link>
          <Link to="/support-us">
            <li className="top-nav-item">SUPPORT US</li>
          </Link> */}
          {curNav2}
        </ul>
      </div>
      <Navbar
        bg="white"
        expand="md"
        className="desk-nav d-none d-lg-flex row pl-2 mx-0 px-0"
      >
        <Link to="/" className="col-2 py-2 px-0 Navbar-brand">
          <Img
            fluid={data.logo.childImageSharp.fluid}
            alt="Logo"
            className="logo main"
            fadeIn={false}
          />
        </Link>
        <Navbar.Collapse id="basic-navbar-nav text-right">
          <Nav className="ml-auto">{curNav}</Nav>
        </Navbar.Collapse>
        <div className="text-right pl-4">
          <a
            href="https://apply.teachforindia.org/"
            target="_blank"
            rel="noreferrer noopener"
            className="butn butn-main mr-tbutn butnxl"
          >
            APPLY
          </a>
        </div>
      </Navbar>
      <div className="mob-nav">
        <Link to="/" className="mob-logo">
          <Img
            fluid={data.logo.childImageSharp.fluid}
            alt="Logo"
            className="logo"
            fadeIn={false}
          />
        </Link>
        <img
          src={menu}
          className="menu-icon"
          onClick={() => {
            document.getElementById("mob-menu").classList.toggle("active")
          }}
        />
      </div>
      <div className="mob-menu" id="mob-menu">
        <Row className="mx-0 logo-cont">
          <Link to="/" className="mob-logo">
            <img src={wlogo} alt="Logo" className="logo" />
          </Link>
          <img
            src={close}
            className="close-icon"
            onClick={() => {
              document.getElementById("mob-menu").classList.toggle("active")
            }}
          />
        </Row>
        <div className="mbn-link-cont">
          <Link
            to="/how-to-join"
            onClick={() => {
              document.getElementById("mob-menu").classList.remove("active")
            }}
            className="mbn-link yellow"
          >
            HOW TO JOIN
          </Link>
          <Link
            to="/life-as-a-fellow"
            onClick={() => {
              document.getElementById("mob-menu").classList.remove("active")
            }}
            className="mbn-link orange"
          >
            Life As A Fellow
          </Link>
          <Link
            to="/life-as-alumni"
            onClick={() => {
              document.getElementById("mob-menu").classList.remove("active")
            }}
            className="mbn-link red"
          >
            Life As Alumni
          </Link>
          <a
            href="https://apply.teachforindia.org/"
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => {
              document.getElementById("mob-menu").classList.remove("active")
            }}
            className="butn butn-main mr-tbutn"
          >
            APPLY
          </a>
        </div>
        <Row className="mob-hlink-container">
          <div className="col-5">
            <Link className="hlink" to="/">
              Home
            </Link>
            <Link
              to="/what-we-do"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              What We Do
            </Link>
            <Link
              to="/our-impact"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Our Impact
            </Link>
            <Link
              to="/innovation"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Innovation
            </Link>
            <Link
              to="/support-us"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Support Us
            </Link>
            <Link
              to="/our-partners"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Our Partners
            </Link>
            <Link
              to="/regions"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Regions
            </Link>
            <Link
              to="/careers"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Careers
            </Link>
            <Link
              to="/our-people"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Our People
            </Link>
          </div>
          <div className="col-7">
            <Link
              to="/media"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Media
            </Link>
            <Link
              to="/faqs"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Faqs
            </Link>
            <Link
              to="/financials"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Financials
            </Link>
            <Link
              to="/privacy-policy"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Privacy Policy
            </Link>
            <Link
              to="/child-protection-policy"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Child Protection Policy
            </Link>
            <Link
              to="/volunteer"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Volunteer
            </Link>
            <Link
              to="/values"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Values
            </Link>
            <Link
              to="/contact"
              onClick={() => {
                document.getElementById("mob-menu").classList.remove("active")
              }}
              className="hlink"
            >
              Contact & Connect
            </Link>
          </div>
        </Row>
        <Row className="text-center px-5">
          <div className="col">
            <a
              href={data.site.siteMetadata.instagram}
              rel="noreferrer"
              target="blank"
            >
              <img src={fins} />
            </a>
          </div>
          <div className="col">
            <a
              href={data.site.siteMetadata.linkedin}
              rel="noreferrer"
              target="blank"
            >
              <img src={flin} />
            </a>
          </div>
          <div className="col">
            <a
              href={data.site.siteMetadata.twitter}
              rel="noreferrer"
              target="blank"
            >
              <img src={ftw} />
            </a>
          </div>
          <div className="col">
            <a
              href={data.site.siteMetadata.youtube}
              rel="noreferrer"
              target="blank"
            >
              <img src={fyt} />
            </a>
          </div>
          <div className="col">
            <a
              href={data.site.siteMetadata.facebook}
              rel="noreferrer"
              target="blank"
            >
              <img src={ffb} />
            </a>
          </div>
        </Row>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  pageName: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  pageName: `Home`,
}

export default Header
