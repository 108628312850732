import { useStaticQuery, graphql, Link } from "gatsby"
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react"
import { Container, Row } from "react-bootstrap"
// eslint-disable-next-line no-unused-vars
import Img from "gatsby-image"
import flogo from "../images/flogo.png"
import fins from "../images/fins.svg"
import ftw from "../images/ftw.svg"
import flin from "../images/flin.svg"
import fyt from "../images/fyt.svg"
import ffb from "../images/ffb.svg"
import fsend from "../images/send.png"
import ins1 from "../images/ins1.png"
import ins2 from "../images/ins2.png"
import ins3 from "../images/ins3.png"
import "../dist/css/footer.css"

const Footer = () => {
  // const insData = 0
  // const url = `https://www.instagram.com/graphql/query/?query_hash=32b14723a678bd4628d70c1f877b94c9&variables={"id":"448863172","first":"3"}`
  // useEffect(() => {
  //   console.log("fetching insta")
  //   fetch(url)
  //     .then(idata => idata.json())
  //     .then(({ idata }) => {
  //       console.log(idata)
  //     })
  // })
  // allInstagramContent(limit: 3) {
  //   edges {
  //     node {
  //       id
  //       localImage {
  //         childImageSharp {
  //           fixed(width: 350, height: 350) {
  //             ...GatsbyImageSharpFixed
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          instagram
          facebook
          linkedin
          youtube
          twitter
        }
      }
    }
  `)
  // console.log(data)
  // allInstaNode(sort: { fields: timestamp, order: DESC }, limit: 3) {
  //   nodes {
  //     id
  //     localFile {
  //       childImageSharp {
  //         fluid {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // }
  return (
    <>
      <Container className="text-center">
        {/* <p className="ins-title">Stories of hope</p> */}
        {/* <Link
          to="https://www.instagram.com/teachforindia/"
          rel="noopener noreferrer"
          className="ins-link d-none d-md-block"
          target="_blank"
        >
          Follow us
        </Link> */}
        {/* <div className="ins-container">
          <Row className="mr-0 mx-md-auto">
            {data.allInstagramContent.edges.map(ins => (
              <div className="col-4 pr-0" key={ins.node.media_id}>
                <Link
                  to={ins.node.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    fixed={ins.node.localImage.childImageSharp.fixed}
                    alt="Instagram Image"
                    className="w-100 insimg"
                  />
                </Link>
              </div>
            ))}
          </Row>
        </div> */}

        {/* <div className="ins-container">
          <Row className="mx-0">
            <div className="col-4 px-2 px-md-3 pr-md-0">
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://www.instagram.com/teachforindia/"
              >
                <img src={ins1} alt="" className="w-100" />
              </a>
            </div>
            <div className="col-4 px-2 px-md-3 pr-md-0">
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://www.instagram.com/teachforindia/"
              >
                <img src={ins2} alt="" className="w-100" />
              </a>
            </div>
            <div className="col-4 px-2 px-md-3 pr-md-0">
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://www.instagram.com/teachforindia/"
              >
                <img src={ins3} alt="" className="w-100" />
              </a>
            </div>
          </Row>
        </div>
      */}
      </Container>
      <div className="footer py-md-5">
        <Container className="desk-footer">
          <Row>
            <div className="col-6">
              <Row>
                <div className="col-3">
                  <Link to="/">
                    <img src={flogo} className="flogo" />
                  </Link>
                </div>
                <div className="col-4">
                  <Link className="flink" to="/">
                    HOME
                  </Link>
                  <Link to="/how-to-join" className="flink">
                    How To Join
                  </Link>
                  <Link to="/life-as-a-fellow" className="flink">
                    Life As A Fellow
                  </Link>
                  <Link to="/life-as-alumni" className="flink">
                    Life As Alumni
                  </Link>
                  <Link to="/what-we-do" className="flink">
                    What We Do
                  </Link>
                  <Link to="/our-impact" className="flink">
                    Our Impact
                  </Link>
                  <Link to="/innovation" className="flink">
                    Innovation
                  </Link>
                  <Link to="/support-us" className="flink">
                    Support Us
                  </Link>
                  <Link to="/our-partners" className="flink">
                    Our Partners
                  </Link>
                  <Link to="/regions" className="flink">
                    Regions
                  </Link>
                </div>
                <div className="col-5">
                  <Link to="/careers" className="flink">
                    Careers
                  </Link>
                  <Link to="/our-people" className="flink">
                    Our People
                  </Link>
                  <Link to="/media" className="flink">
                    Media
                  </Link>
                  <Link to="/faqs" className="flink">
                    Faqs
                  </Link>
                  <Link to="/financials" className="flink">
                    Financials
                  </Link>
                  <Link to="/privacy-policy" className="flink">
                    Privacy Policy
                  </Link>
                  <Link to="/child-protection-policy" className="flink">
                    Child Protection Policy
                  </Link>
                  <Link to="/volunteer" className="flink">
                    Volunteer
                  </Link>
                  <Link to="/values" className="flink">
                    Values
                  </Link>
                  <Link to="/contact" className="flink">
                    Contact & Connect
                  </Link>
                </div>
              </Row>
            </div>
            <div className="col-6">
              <Row>
                <div className="col-1 px-0"></div>
                {/* <div className="col-5 pl-0">
                  <form name="subscribe" method="POST" data-netlify="true">
                    <div className="finput">
                      <input
                        placeholder="Email"
                        className="finput-field"
                        type="email"
                        name="email"
                      />
                      <button type="submit">
                        <img src={fsend} />
                      </button>
                    </div>
                    <p className="fsub-txt">
                      Stay in touch with us for the latest updates.
                    </p>
                  </form>
                </div> */}
                <div className="col-6 text-center">
                  <Row>
                    <div className="col">
                      <a
                        href={data.site.siteMetadata.instagram}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src={fins} />
                      </a>
                    </div>
                    <div className="col">
                      <a
                        href={data.site.siteMetadata.linkedin}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src={flin} />
                      </a>
                    </div>
                    <div className="col">
                      <a
                        href={data.site.siteMetadata.twitter}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src={ftw} />
                      </a>
                    </div>
                    <div className="col">
                      <a
                        href={data.site.siteMetadata.youtube}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src={fyt} />
                      </a>
                    </div>
                    <div className="col">
                      <a
                        href={data.site.siteMetadata.facebook}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src={ffb} />
                      </a>
                    </div>
                  </Row>
                </div>
              </Row>
            </div>
          </Row>
        </Container>

        <Container className="mob-footer">
          <Row className="text-center">
            <div className="col">
              <a
                href={data.site.siteMetadata.instagram}
                rel="noreferrer"
                target="_blank"
              >
                <img src={fins} />
              </a>
            </div>
            <div className="col">
              <a
                href={data.site.siteMetadata.linkedin}
                rel="noreferrer"
                target="_blank"
              >
                <img src={flin} />
              </a>
            </div>
            <div className="col">
              <a
                href={data.site.siteMetadata.twitter}
                rel="noreferrer"
                target="_blank"
              >
                <img src={ftw} />
              </a>
            </div>
            <div className="col">
              <a
                href={data.site.siteMetadata.youtube}
                rel="noreferrer"
                target="_blank"
              >
                <img src={fyt} />
              </a>
            </div>
            <div className="col">
              <a
                href={data.site.siteMetadata.facebook}
                rel="noreferrer"
                target="_blank"
              >
                <img src={ffb} />
              </a>
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Footer
